.project-sample-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 89%;
  height: auto;
  scale: 90%;
  /* max-width: 95%; */
  border-radius: 9px;
  align-items: center;
  margin: 30px auto;
  margin-bottom: 12%;
  /* opacity: 0.89; */
  border: 1.5px solid #bebbae;
  /* background-color: #f6f6f6; */
  /* background-color: rgba(77, 76, 73, 0.906); */
  background: linear-gradient(-30deg, #545349, #2e2e2c 60%, #2e2e2c 60%);
  padding: 20px 20px 20px 20px;
  /* animation: fadeIn 1.5s backwards; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 10px 13px 13px 12px rgba(0, 0, 0, 0.3);
}

.hidden-element {
  opacity: 0;
  transition: opacity 0.5s ease; /* Smooth transition for visibility */
}

.visible-element {
  opacity: 1;
  transition: opacity 0.5s ease;
}

/* .scroller {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  & > div { 
    
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

} */

.project-sample-container p {
  align-items: center;
  justify-content: center;
}

.contact-message {
  font-size: 1.6vw;
}

.project-sample-container2 {
  position: relative;
  width: 70%;
  height: 100%;
  max-width: 85%;
  border-radius: 9px;
  margin: 20px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-sampler p {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  border-radius: 9px;
  margin: 20px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-sampler1 {
  position: relative;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  border-radius: 9px;
  margin: 20px auto;
  /* margin-bottom: 5px; */
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-buttons {
  width: 60%;
  scale: 80%;
}

.container-buttons {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
}

.cart-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cart-buttons-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* margin-top: 10%; */
}


.checkout-buttons2 {
  width: 70%;
  scale: 70%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #0e0e0e;
  color: white;
  font-size: 1.7rem;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.sale-items {
  height: 800;
  margin-bottom: 100px;
  background-image: url('../image/yellw.png');
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two equal-width columns */
  row-gap: 100px;
}

.qty {
    width: 30px;
  }

.project-image-container {
  position: relative;
  width: 100%;
  scale: 90%;
  justify-content: center;
  border-radius: 9px;
  /* height: 500px; */
}

.project-image-container:hover {
  transform: scale(1.05);
  transition-duration: 3s;
  border-radius: 9px;
}

.project-image {
  width: 100%;
  border-radius: 9px;
  justify-content: center;
  transition: transform 0.9s ease, box-shadow 0.9s ease;
}

.project-image:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.project-link:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}

.overlay-text {
  font-size: 1.2em;
  padding-bottom: 40px;
  text-align: center;
}

.project-details {
  display: flex;
  flex-direction: row;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 99%;
}

.details-box {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  width: 100%;
}

.details-box1 {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  /* justify-content: center; */
  width: 100%;
}

.project-details-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.project-title {
  font-size: 2vw;
  font-weight: 500;
  color: #e2b72a;
}

.scroll-erase {
  padding-left: 60%;
  padding-bottom: 10%;
  color: #000000;
  font-weight: 700;
  animation: fadeOut 0.6s forwards;
  animation-timeline: scroll();
}


.section-project-description {
  width: 90%;
  background: linear-gradient(-18deg, #e3d89c, #f6ce0b 40%,  #F3CB01 60%, #c0b056, #e3d89c);
  box-shadow: 14px 10px 28px rgba(0, 0, 0, 0.378);
  margin-top: 10%;
  /* margin-bottom: 185px;   */
  font-size: 1em;
  padding-left: 10%;
  text-align: justify;
  align-items: center;
  line-height: 1.6;
  opacity: 1;
  animation: fadeInLeft 1s forwards;
  /* animation-timeline: scroll();   */
}

        

@keyframes fadeIn {
    0% { 
      transform: translate(-100%);
      opacity: 0; }
    50% { 
      transform: translate(0);
      opacity: 1; }
}
@keyframes fadeOut {
    0% { opacity: 0; transform: translateX(100%);  }
    80% { opacity: 1;  }
}


@keyframes fadeUp {
    0% { opacity: 0; transform: translateY(100%);  }
    80% { opacity: 1;  }
}

.fade-out {
  animation: fadeIn 2s ;
}

.fade-in {
  animation: fadeOut 2s ;
}

@keyframes fadeInP {
    0% { 
      transform: translateX(-10%);
      opacity: 0; }
    60% { 
      transform: translateX(0);
      opacity: 1; }
}
@keyframes fadeOutP {
    0% { 
      transform: translateX(-10%);
      opacity: 0;
     }
    70% { 
      transform: translateX(0);
      opacity: 1;
     }
}

.project-fade-out {
  animation: fadeInP 2s ;
}

.project-fade-in {
  animation: fadeOutP 2s ;
}

@keyframes fadeInC {
    0% { 
      transform: translateX(100%);
      opacity: 0; }
    60% { 
      transform: translateX(0);
      opacity: 1; }
}
@keyframes fadeOutC {
    0% { opacity: 1; }
    70% { opacity: 0; }
}

.container-fade-out {
  animation: fadeInC 2s ;
}

.container-fade-in {
  animation: fadeOutC 2s ;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(20%);
    opacity: 0;
    scale: 90%
  }
  60% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-20%);
    opacity: 0;
    scale: 80%;
  }
  60% {
    transform: translateX(0);
    opacity: 1;
  }
}

.project-slide-in-right {
  animation: fadeInRight 3s;
}

.project-slide-in-left {
  animation: fadeInLeft 3s;
}


.item-description {
  font-size: 1rem;
}

.project-description2 {
  font-size: 1.3vw;
  text-align: justify;
  width: 80%;
  /* color: #000000; */
  /* background-color: #f3cb01; */
  background: linear-gradient(-18deg, #e9e3c0, #f6ce0b 40%,  #F3CB01 60%, #e9e3c0);
  border-radius: 12px;
  box-shadow: 14px 10px 28px rgba(0, 0, 0, 0.378);
  font-weight: 700;
  align-content: center;
  padding: 2%;
  line-height: 1.6;
  animation: fadeOut 2s forwards;
  /* animation: scroll-marquee 7s vertical infinite; */
  margin-bottom: 10%;
  /* animation-timeline: scroll(); */
}

.project-description3 {
  font-size: 1em;
  text-align: justify;
  width: 80%;
  font-weight: 300;
  color: #000000;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  
}

.project-description {
  margin-bottom: 15px;
  font-size: 1.2vw;
  width: 80%;
  letter-spacing: 2.7px;
  color: #ebeae6;
  justify-self: center;
  text-align: justify;
  line-height: 1.5;
}

/* .project-description::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  pointer-events: none;
} */

/* Styling for subTitle */
.subTitle {
  font-size: 0.9em;
  height: 29px;
  font-weight: 500;
  padding-left: 40px;
  margin: 5px 0 0px 0;
  color: #e2b72a;
  /* border-bottom: 2px solid #ddd; */
  padding-bottom: 5px;
  width: 100%;
  text-align: left;
}

/* Styling for feature */
.feature-container {
  display: flex;
  flex-direction: row;
  overflow:visible;
  align-items: flex-start;
  /* gap: 4px; Adds space between features */
  width: 100%;
}

.tech-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; /* Adds space between features */
  width: 100%;
}



.feature {
  font-size: 1.19vw;
  margin: 0px 0;
  color: #ebeae6;
  height: auto;
  width: 99%;
  letter-spacing: 1.2px;
  box-sizing: border-box;
  padding-left: 20px;
  text-align: left;
  line-height: 1.6;
}

.feature::before {
  content: "•";
  margin-right: 5px;
  color: #007BFF; /* Change this color as needed */
  font-size: 1.5em;
}

.app-store-badge {
  width: 180px;
  height: auto;
  margin-top: 20px;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.app-store-badge:hover {
  transform: scale(1.05);
  transition-duration: 0.8s;
}


        @keyframes breath {
  from {
    animation-timing-function: ease-out;
  }

  to {
    transform: scale(1.25) translateY(-5px) perspective(1px);
    text-shadow: 0 0 40px var(--glow-color);
    animation-timing-function: ease-in-out;
  }
}

@media (prefers-reduced-motion:no-preference) {
  [letter-animation] > span {
    display: inline-block;
    white-space: break-spaces;
  }

  [letter-animation=breath] {
    --glow-color: white;
  }

  [letter-animation=breath]>span {
    animation: breath 1.2s ease calc(var(--index) * 100 * 1ms) infinite alternate;
  }
}

@media (prefers-reduced-motion:no-preference) and (prefers-color-scheme: light) {
  [letter-animation=breath] {
    --glow-color: black;
  }
}
        

#contact {
  width: 75%;
  /* max-width: 600px; */
  /* margin: 0 auto; */
  /* margin-bottom: 3%; */
  /* padding: 10px; */
  height: 80%;
  /* background-image: url('../image/yellw.png'); */
  /* background-color: #F3CB01; */
  background: linear-gradient(-13deg, #545349, #191918 60%, #191918 60%);
  border-radius: 8px;
  margin-bottom: 17%;
  box-shadow: 14px 10px 8px rgba(0, 0, 0, 0.1);
}


#contact h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.8rem;
}

#contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-form label {
  margin-bottom: 5px;
  font-weight: 500;
  color:rgb(245, 245, 245)
}


#contact-form input,
#contact-form textarea {
  /* margin-bottom: 15px; */
  padding: 10px;
  width: 60%;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  border-radius: 4px;
}

#contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #2a2929;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#contact-form button:hover {
  background-color: #010101;
}

@media (max-width: 768px) {
  .project-sample-container {
    width: 90%;
    /* padding: 10x; */
    padding: 0px;
  }

  .contact-message {
  font-size: 2.6vw;
}

  .project-details {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  width: 100%;
  text-align: center;
  }

  .details-box {
  display: flex;
  /* grid-template-rows: repeat(2, 1fr); */
  align-items: center;
  flex-direction: row;
  width: 100%;
  }

  .checkout-buttons2 {
  width: 100%;
  scale: 70%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 1.7rem;
  /* display: inline-block; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

  .cart-buttons {
  display: flex;
  flex-direction: row;
  }

  .project-sampler1 {
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex; */
  max-width: 90%;
  border-radius: 9px;
  margin: 20px auto;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .sale-items {
  display: flex;
  flex-direction: column;
  /* height: 800; */
  margin-top: -20%;
  scale: 80%;
  max-width: 110%;
  margin-bottom: 40px;
  }

  .qty {
    width: 30px;
  }

  .project-description2 {
  margin-bottom: 115px;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: justify;
  width: 100%;
  align-content: center;
  padding: 0px 0px 0px 0px;
  line-height: 1.6;
}

.section-project-description {
  height: 30%;
  width: 100%;
  background-color: #f3cb01;
  margin-top: 85px;
  font-size: 1em;
  padding-left: 10%;
  text-align: justify;
  color: #000000;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  animation: fadeOut 3s backwards;
  animation-timeline: scroll();
}

  .project-sampler {
  width: 100%;
  height: 100%;
  /* max-width: 300px; */
}

  .feature-container {
    overflow: hidden;
  }

  .project-title {
    font-size: 1.8em;
    width: 70%;
  }

  .project-description {
    font-size: 0.8em;
    width: 84%;
    padding: 10px 0 10px 0;
  }

  .subTitle {
    font-size: 1.4em;
  }

  .feature {
    font-size: 1em;
    width: 80%;
  }

  .app-store-badge {
    width: 160px;
  }

  #contact {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



  #contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #040404;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
}

@media (max-width: 480px) {
  .project-sample-container {
    width: 100%;
    /* margin: 10px; */
    padding: 0px;
    padding-bottom: 10px;
  }
  .sale-items {
  flex-direction: column;
  height: 800;
  margin-top: -70%;
  margin-bottom: 40px;
  }

  .cart-buttons {
  display: flex;
  flex-direction: row;
  width: 140%;
  }

  .project-details {
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  align-items: start;
  width: 100%;
  text-align: center;
  }

  .details-box {
  display: flex;
  /* grid-template-rows: repeat(2, 1fr); */
  align-items: center;
  flex-direction: column;
  width: 100%;
  }

  .checkout-buttons2 {
  width: 100%;
  scale: 80%;
  height: 30%;
  /* padding: 10px 15px; */
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 0.9rem;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

  .project-description2 {
  margin-bottom: 115px;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: justify;
  width: 80%;
  align-content: center;
  padding: 0px 0px 0px 0px;
  line-height: 1.6;
}

  .project-sampler {
    width: 100%;
    height: 100%;
    max-width: 85%;
}

  .feature-container {
    overflow: hidden;
  }

  .project-title {
    font-size: 1.6em;
    width: 70%;
  }

  .project-description {
    font-size: 1em;
    width: 84%;
    /* padding: 10px 0 10px 0; */
  }

  .subTitle {
    padding-left: 25px;
    font-size: 1.3em;
  }

  .feature {
    font-size: 0.9em;
    padding-left: 15px;
  }

  .app-store-badge {
    width: 160px;
  }

  #contact {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  #contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #0c0c0c;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
}
