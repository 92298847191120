.flex-container {
  display:inline;
  justify-content:space-around;
  align-items: center;
  /* width: 100%; */
  position: relative;
  padding: 3%
}

.paragraph {
  font-size: 1.4vw;
  float: left;
  font-weight: 200;
  width: 90%;
  color: #ebeae6;
  text-align: justify;
}
.text-content {
  flex-grow: 1;
}


.image-container2 {
  /* width: 100%; */
  height: 100%;
  width: 90%;
  padding-top: 10%;
  justify-content: center;
  object-fit: contain;
}

.image-container img {
  width: 100%;
  height: 100%;
  width: 300px;
  /* height: 300px; */
  /* padding-left: 190px; */
  justify-content: center;
  object-fit: contain;
}

.image-container2 img {
  width: 100%;
  height: 100%;
  width: 90%;
  /* padding-left: 190px; */
  justify-content: center;;
  object-fit: contain;
}

.project-image-container {
  position: relative;
  width: 100%;
  justify-content: center;
  border-radius: 9px;
  height: auto;
}

@media (max-width: 768px) {
    .paragraph {
        font-size: 1.5rem;
    }

    .image-container img {
      padding-left: 0px;
    }

    .image-container {
      width: 200px;
      height: 200px;
    }

    .flex-container {
        padding: 20px;
        padding-right: 30px;
    }
}


@media (max-width: 480px) {
    .paragraph {
        font-size: 1rem;
    }

    .image-container {
      width: 200px;
      height: 200px;
    }

    .image-container img {
      padding-left: 0px;
    }

    .flex-container {
        padding: 20px;
        padding-right: 30px;
    }
}