.Heading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: transparent;
}

/* .job-title {
  font-size: 2rem;
  font-weight: 300;
} */

.job-title {
  font-size: .5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;    /* Hide overflow */
  display: inline-block;
  -webkit-text-stroke: 5px rgb(240, 239, 236);
  /* -webkit-text-stroke: none; */
  /* text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.498); */
  animation: scroll-marquee 14s linear infinite;
}

@keyframes scroll-marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Optional container to ensure the marquee is constrained */
.marquee-container {
  width: 90%; /* or desired width */
  overflow: hidden;
  margin: 0 auto;
  display: block;
  position: relative;
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 80%, transparent);
  mask-image: linear-gradient(to right, transparent, black 8%, black 80%, transparent);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  /* animation: scroll-marquee 7s linear infinite; */
}


.headshot {
  border-radius: 9px;
  scale: 92%;
  box-shadow: 8px 8px 28px 8px rgba(57, 57, 57, 0.333);
}

.payments {
  border-radius: 9px;
}

.payments-section {
  padding-left: 20%;
}

.payments-section2 {
  padding-left: 0%;
  scale: 80%;
}

#tsparticles {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0; /* Behind content */
  overflow-y: auto;
}

.Bod {
  flex-grow: 1;
  text-align: center;
  /* background-color:#0b0d0e;  */
  background: linear-gradient(-220deg, #111111bd, #0a0a0a 60%, #0a0a0a 60%);
  overflow-y: auto;
  overflow-x: none;
  height: 100vh;
  height: calc(100vh - 60px);
  z-index: 1;
}

.prjLink {
  color: rgb(98, 106, 180);
}

.prjLink:hover {
  color: #2a1691;
}

.tossing {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 79%;
  /* justify-self: center; */
  font-size: 3.5em;
  paint-order: stroke fill;
  -webkit-text-stroke: 6px rgb(225, 223, 208);
  letter-spacing: 0.1ch;
  font-weight: 500;
  color: rgb(198, 164, 27);
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.498);
}

.title-heading {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 79%;
  font-size: 3.3em;
  paint-order: stroke fill;
  -webkit-text-stroke: 5px rgb(225, 223, 208);
  letter-spacing: 0.1ch;
  margin-top: 7%;
  font-weight: 500;
  color: rgb(198, 164, 27);
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.498);
}


/* .tossh3 {
  width: 70%;
} */

.tossing h3 {
  font-size: 2rem;
  font-weight: 300;
}

.tossing2 {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  font-size: 2vw;
  margin-bottom: 1vw;
  margin-top: 4vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes toss {
  0%, 100% { transform: rotate(0deg); }
  75% { transform: rotate(-0.5deg); }
}


.Content {
  font-size:medium;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  /* background-color:#000000; */
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.Content2 {
  width: 100%;
}

.upper-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.upper-section-mobile {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* border-radius: 20px; */
  width: 100%;
  height: 93%;
  align-items: center;
}

.upper-section2 {
  text-align: center;
  padding-left: 5%;
  margin-top: 3em;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* border-radius: 20px; */
  width: 80%;
  height: 83%;
  align-items: center;
}

.fixed-bottom-right-button {
  position: fixed;
  bottom: -1%;
  right: 20px;
  padding: 15px 123px;
  font-size: 1.8rem;
  background-color: #101010;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: height .4s ease;
  transition: all 0.3s ease;
}

.fixed-bottom-right-button-mobile {
  position: fixed;
  bottom: -10px;
  right: 8%;
  width: 350px;
  height: 50px;
  /* padding: 15px 123px; */
  font-size: 1.5rem;
  background-color: #212121;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: height 0.4s ease;
  transition: all 0.3s ease;
}


.expanded-cart {
  position: fixed;
  bottom: -5px;
  max-height: 100%;
  width: 37%;
  max-width: auto;
  right: -5px;
  padding: 20px;
  font-size: 1.8rem;
  border: 2.4px solid #282828;
  background-color: rgb(255, 255, 255);
  /* box-shadow: inset 3px 6px 10px rgba(0, 0, 0, 0.27); */
  color: #000000;
  overflow-y: auto;
  border-radius: 9px;
  height: 75%;
  -webkit-transition: height 0.4s ease;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.cart-line-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: center;
  scale: 80%;
  gap: 10px;
}

.fixed-bottom-right-button:hover {
  background-color: #2f2f2f;
}



.upper-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #97bbf9;
}

.lower-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 20px;
  width: 100%;
  margin-top: 5%;
  background-color: transparent;
  /* height: 53%; */
  align-items: center;
}

.lower-section2 {
  display: flex;
  flex-direction: column;
  /* grid-template-rows: repeat(1, 1fr); Two equal-width columns */
  /* row-gap: 90px; */
  /* border-radius: 20px; */
  /* gap: 5%; */
  width: auto;
  /* background-color: #000000; */
  height: 100%;
  /* padding-bottom: 3%; */
  margin-bottom: 20px;
  align-items: center;
  animation-timeline: scroll(); /* Scroll Animation */
}

/* Scroll Animation */
@keyframes fadeIn {
    0% { opacity: 0; }
    5% { opacity: 1; }
}

.App-header {
  position: fixed;
  width:auto;
  height: 2%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 100%;
  backdrop-filter: blur(3px);
  background-color: transparent;
  /* background-color: linear-gradient(to bottom, transparent, black 2%, transparent); */
  font-size: calc(15px + 2vmin);
  /* color: transparent; */
  padding: 20px 10px ;
  z-index: 10;
  mask-image: linear-gradient(to bottom, transparent 7%, black, black 90%, transparent); 
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 2%, black 96%, transparent);
  
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .Content {
  flex-direction: column;
  }
  .tossing {
    font-size: 3rem;
    -webkit-text-stroke: 5.3px rgb(225, 223, 208);
    /* text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.498); */
  }

  .job-title {
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  }

  .tossing2 {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  font-size: 4vw;
  margin-bottom: 1vw;
  margin-top: 4vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .headshot {
  border-radius: 9px;
  scale: 82%;
  box-shadow: 8px 8px 28px 8px rgba(57, 57, 57, 0.333);
  }

  .expanded-cart {
  position: fixed;
  bottom: 30px;
  height: auto;
  max-height: 60%;
  max-width: 80%;
  right: 20px;
  padding: 20px 60px;
  font-size: 1.2rem;
  }

  .title-heading {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 79%;
  /* justify-self: center; */
  font-size: 3rem;
  /* text-decoration: underline; */
  text-underline-offset: 1rem;
  paint-order: stroke fill;
  -webkit-text-stroke: 3px rgb(228, 223, 175);
  letter-spacing: 0.1ch;
  margin-top: 25%;
  font-weight: 500;
  /* color: #0b0d0e;  */
  text-shadow: 2vw 2vw 4vw rgba(0, 0, 0, 0.3);
  }

  .cart-line-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: center;
  scale: 60%;
  gap: 7px;
  }

  .fixed-bottom-right-button {
  position: fixed;
  bottom: -10px;
  right: 20px;
  padding: 20px 123px;
  font-size: 1.2rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  }

  /* .upper-section2 {
  padding-top: 20px;
  text-align: center;
  padding-left: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-radius: 20px;
  width: 80%;
  height: 93%;
  align-items: center;
} */

.lower-section2 {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  /* display: grid; */
  row-gap: 90px;
  border-radius: 20px;
  width: 100%;
  height: 73%;
  padding-top: 40px;
  align-items: center;
  /* overflow-y: auto; */
}

  .upper-section {
  /* padding: 20px; */
  text-align: center;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 20px;
  width: 90%;
  height: 93%;
  padding-top: 20px;
  align-items: center;
  overflow-y: auto;
  }
  /* .upper-section2 {
  padding-top: 20px;
  text-align: left;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 20px;
  width: 80%;
  height: 93%;
  align-items: center;
} */
  
  .App-header {
  /* background-color: #343a40; */
  /* width:auto; */
  display:flex;
  width: 90vw;
  align-items: center;
  justify-content: left;
  font-size: calc(15px + 2vmin);
  color: white;
  padding: 10px;
}

  .Content {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 17vh; */
    width: 100%;
    /* height: 100%; */
  }

  .Bod {
    /* padding-top: 5vh; */
    overflow: auto;
    width: auto;
  }
}

@media (max-width: 480px) {
  .Content {
  flex-direction: column;
}
  .tossing {
    font-size: 3rem; /* Adjust font size for smaller screens */
    -webkit-text-stroke: 5.3px rgb(225, 223, 208);
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.498);
  }
  .job-title {
  font-size: 1.2rem;
  }

  .job-title3 {
  font-size: 2.2rem;
  color: rgb(198, 164, 27);
  -webkit-text-stroke: 1px rgb(225, 223, 208);
  }

  .expanded-cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: auto;
  max-height: 60%;
  /* width: auto; */
  max-width: 80%;
  right: 20px;
  padding: 20px 100px;
  font-size: 1.2rem;
  }

  .cart-line-item {
    display: flex;
    justify-content: flex-end;
    justify-content: center;
    flex-direction: row;
    scale: auto;
    gap: 7px;
  }

  .fixed-bottom-right-button {
    display: flex;
  }

  .fixed-bottom-right-button {
  position: fixed;
  bottom: -15px;
  right: 20px;
  padding: 12px 123px;
  font-size: 1.2rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  }


  .upper-section2 {
  padding-top: 20px;
  text-align: center;
  padding-left: 5px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 20px;
  width: 90%;
  height: 93%;
  align-items: center;
  }

  .lower-section2 {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  /* display: grid; */
  row-gap: 90px;
  border-radius: 20px;
  width: 100%;
  height: 73%;
  padding-top: 40px;
  align-items: center;
  /* overflow-y: auto; */
  }

  .upper-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  place-items: center;
  flex-grow: 1;
  width: 96%;
  height: 93%;
  align-items: center;
  overflow-y: auto;
  }
  
  .App-header {
  /* background-color: #343a40; */
  width:95vw;
  display:flex;
  /* max-width: 120px; */
  height: 3.5%;
  align-items: center;
  justify-content: left;
  /* font-size: calc(15px + 2vmin); */
  color: white;
  /* padding: 10px; */
}

  .Content {
    font-size: 1rem;
    width: 100%; /* Ensure full width usage */
    margin: 0 auto;
    padding-bottom: 20px; /* Add padding to avoid cutoff */
  }

  .Bod {
    /* padding-top: 5vh; */
    overflow: auto;
    width: auto;
  }
}